/* montserrat-100 - latin */
@font-face {
  font-family: 'Montserrat-light';
  font-style: normal;
  font-weight: 100;
  src: url("../fonts/Montserrat/montserrat-v13-latin-100.eot");
  /* IE9 Compat Modes */
  src: local("Montserrat Thin"), local("Montserrat-Thin"), url("../fonts/Montserrat/montserrat-v13-latin-100.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat/montserrat-v13-latin-100.woff2") format("woff2"), url("../fonts/Montserrat/montserrat-v13-latin-100.woff") format("woff"), url("../fonts/Montserrat/montserrat-v13-latin-100.ttf") format("truetype"), url("../fonts/Montserrat/montserrat-v13-latin-100.svg#Montserrat") format("svg");
  /* Legacy iOS */ }

/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Montserrat/montserrat-v13-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Montserrat Regular"), local("Montserrat-Regular"), url("../fonts/Montserrat/montserrat-v13-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat/montserrat-v13-latin-regular.woff2") format("woff2"), url("../fonts/Montserrat/montserrat-v13-latin-regular.woff") format("woff"), url("../fonts/Montserrat/montserrat-v13-latin-regular.ttf") format("truetype"), url("../fonts/Montserrat/montserrat-v13-latin-regular.svg#Montserrat") format("svg");
  /* Legacy iOS */ }

/* montserrat-800 - latin */
@font-face {
  font-family: 'Montserrat-bold';
  font-style: normal;
  font-weight: 800;
  src: url("../fonts/Montserrat/montserrat-v13-latin-800.eot");
  /* IE9 Compat Modes */
  src: local("Montserrat ExtraBold"), local("Montserrat-ExtraBold"), url("../fonts/Montserrat/montserrat-v13-latin-800.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat/montserrat-v13-latin-800.woff2") format("woff2"), url("../fonts/Montserrat/montserrat-v13-latin-800.woff") format("woff"), url("../fonts/Montserrat/montserrat-v13-latin-800.ttf") format("truetype"), url("../fonts/Montserrat/montserrat-v13-latin-800.svg#Montserrat") format("svg");
  /* Legacy iOS */ }

@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon.eot?onsvam");
  src: url("../fonts/icomoon.eot?onsvam#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?onsvam") format("truetype"), url("../fonts/icomoon.woff?onsvam") format("woff"), url("../fonts/icomoon.svg?onsvam#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-eurodreams_color .path1:before {
  content: "\e92b";
  color: #692e87; }

.icon-eurodreams_color .path2:before {
  content: "\e92c";
  margin-left: -0.9951171875em;
  color: #e62d69; }

.icon-eurodreams_color .path3:before {
  content: "\e92d";
  margin-left: -0.9951171875em;
  color: #e62d69; }

.icon-eurodreams_color .path4:before {
  content: "\e92e";
  margin-left: -0.9951171875em;
  color: #e62d69; }

.icon-eurodreams_color .path5:before {
  content: "\e92f";
  margin-left: -0.9951171875em;
  color: #e62d69; }

.icon-eurodreams_color .path6:before {
  content: "\e930";
  margin-left: -0.9951171875em;
  color: #e62d69; }

.icon-eurodreams_color .path7:before {
  content: "\e931";
  margin-left: -0.9951171875em;
  color: #e62d69; }

.icon-eurodreams_color .path8:before {
  content: "\e932";
  margin-left: -0.9951171875em;
  color: #2bbcee; }

.icon-eurodreams_color .path9:before {
  content: "\e933";
  margin-left: -0.9951171875em;
  color: #2bbcee; }

.icon-eurodreams_color .path10:before {
  content: "\e934";
  margin-left: -0.9951171875em;
  color: #692e87; }

.icon-eurodreams_color .path11:before {
  content: "\e935";
  margin-left: -0.9951171875em;
  color: #692e87; }

.icon-eurodreams_color .path12:before {
  content: "\e936";
  margin-left: -0.9951171875em;
  color: #44af6e; }

.icon-eurodreams_color .path13:before {
  content: "\e937";
  margin-left: -0.9951171875em;
  color: #44af6e; }

.icon-eurodreams_color .path14:before {
  content: "\e938";
  margin-left: -0.9951171875em;
  color: #ec70a2; }

.icon-eurodreams_color .path15:before {
  content: "\e939";
  margin-left: -0.9951171875em;
  color: #ec70a2; }

.icon-eurodreams_color .path16:before {
  content: "\e93a";
  margin-left: -0.9951171875em;
  color: #ec70a2; }

.icon-eurodreams_color .path17:before {
  content: "\e93b";
  margin-left: -0.9951171875em;
  color: #ec70a2; }

.icon-eurodreams_color .path18:before {
  content: "\e93c";
  margin-left: -0.9951171875em;
  color: #ef7e58; }

.icon-eurodreams_color .path19:before {
  content: "\e93d";
  margin-left: -0.9951171875em;
  color: #ef7e58; }

.icon-eurodreams:before {
  content: "\e93e"; }

.icon-caballo:before {
  content: "\e915"; }

.icon-botes:before {
  content: "\e916"; }

.icon-logo-liga-color .path1:before {
  content: "\e917";
  color: white; }

.icon-logo-liga-color .path2:before {
  content: "\e918";
  margin-left: -1em;
  color: #951b81; }

.icon-logo-liga-color .path3:before {
  content: "\e919";
  margin-left: -1em;
  color: #004f9f; }

.icon-logo-liga-color .path4:before {
  content: "\e91a";
  margin-left: -1em;
  color: #0090d7; }

.icon-logo-liga-color .path5:before {
  content: "\e91b";
  margin-left: -1em;
  color: #009640; }

.icon-logo-liga-color .path6:before {
  content: "\e91c";
  margin-left: -1em;
  color: #ffed00; }

.icon-logo-liga-color .path7:before {
  content: "\e91d";
  margin-left: -1em;
  color: #f18700; }

.icon-logo-liga-color .path8:before {
  content: "\e91e";
  margin-left: -1em;
  color: #e30613; }

.icon-logo-liga-color .path9:before {
  content: "\e91f";
  margin-left: -1em;
  color: black; }

.icon-logo-liga-color .path10:before {
  content: "\e920";
  margin-left: -1em;
  color: black; }

.icon-clasificacion:before {
  content: "\e921"; }

.icon-alarm:before {
  content: "\e900"; }

.icon-time:before {
  content: "\e900"; }

.icon-clock:before {
  content: "\e900"; }

.icon-search:before {
  content: "\e901"; }

.icon-zoom:before {
  content: "\e901"; }

.icon-magnifier:before {
  content: "\e901"; }

.icon-magnifying-glass:before {
  content: "\e901"; }

.icon-close:before {
  content: "\e902"; }

.icon-chevrons-right:before {
  content: "\e903"; }

.icon-arrow-right-circle:before {
  content: "\e904"; }

.icon-arrow-right:before {
  content: "\e905"; }

.icon-chevron-right:before {
  content: "\e906"; }

.icon-chevrons-left:before {
  content: "\e907"; }

.icon-arrow-left-circle:before {
  content: "\e908"; }

.icon-arrow-left:before {
  content: "\e909"; }

.icon-chevron-left:before {
  content: "\e90a"; }

.icon-bonoloto:before {
  content: "\e90b"; }

.icon-estrella:before {
  content: "\e90c"; }

.icon-lototurf:before {
  content: "\e90e"; }

.icon-nacional:before {
  content: "\e90f"; }

.icon-primitiva:before {
  content: "\e910"; }

.icon-quiniela:before {
  content: "\e911"; }

.icon-quinigol:before {
  content: "\e912"; }

.icon-quintupleplus:before {
  content: "\e913"; }

.icon-calendar:before {
  content: "\e914"; }

.icon-date:before {
  content: "\e914"; }

.icon-schedule:before {
  content: "\e914"; }

.icon-euromillon:before {
  content: "\e92a"; }

@-webkit-keyframes toTopFromBottom {
  0% {
    -webkit-transform: translateY(50%);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0%);
    opacity: 1; } }

@-webkit-keyframes show {
  0% {
    opacity: 0; }
  66% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes a-opacity {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes right-animation {
  0% {
    transform: translateY(110%); }
  30% {
    transform: translateY(110%); }
  100% {
    transform: translateY(0%); } }

@keyframes leftAnimation {
  0% {
    transform: translateX(110%); }
  30% {
    transform: translateX(110%); }
  100% {
    transform: translateX(0%); } }

@keyframes leftOpacity {
  0% {
    opacity: 0; }
  30% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes home-man-appear {
  0% {
    transform: translate(-50%, 40%);
    opacity: 0; }
  30% {
    transform: translate(-50%, 40%);
    opacity: 0; }
  100% {
    transform: translate(-50%, 0%);
    opacity: 1; } }

html {
  font-size: 100%;
  -webkit-box-sizing: border-box;
  margin: 0;
  padding: 0;
  height: 100%;
  background: #fff; }

body {
  margin: 0;
  padding: 0;
  height: 100%;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden; }
  body #app {
    display: block;
    position: relative;
    overflow: hidden;
    margin: 0;
    padding: 0;
    height: 100%; }
    body #app .error-msg {
      font-family: "Montserrat", sans-serif;
      font-size: 2rem;
      color: red;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      height: 100%; }
    body #app .loading-msg {
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: #fff;
      z-index: 9999;
      justify-content: center;
      align-items: center;
      flex-direction: column; }
      body #app .loading-msg img {
        width: 9rem; }
      body #app .loading-msg span {
        font-family: "Montserrat", sans-serif;
        font-size: 4rem;
        margin-bottom: 2rem;
        color: #aaa;
        text-align: center; }
    body #app #capa_offline {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 99998;
      background: rgba(0, 0, 0, 0.3); }
      body #app #capa_offline span {
        color: red;
        font-size: 3rem;
        font-family: "Montserrat", sans-serif;
        font-weight: bold; }
    body #app #capa_cargando {
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: #fff;
      z-index: 9999;
      justify-content: center;
      align-items: center;
      flex-direction: column; }
      body #app #capa_cargando img {
        width: 9rem; }
      body #app #capa_cargando span {
        font-family: "Montserrat", sans-serif;
        font-size: 4rem;
        margin-bottom: 2rem;
        color: #aaa;
        text-align: center; }
    body #app #logout {
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 99999;
      width: 7rem;
      height: 7rem;
      border: 0;
      background: transparent;
      cursor: pointer;
      margin: 0;
      padding: 0; }
    body #app #reload {
      display: block;
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 99999;
      width: 7rem;
      height: 7rem;
      border: 0;
      background: transparent;
      cursor: pointer;
      margin: 0;
      padding: 0; }
    body #app .login {
      text-align: center;
      font-family: "Montserrat", sans-serif;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
      body #app .login .contenido-login h1 {
        font-size: 2rem;
        text-transform: uppercase;
        margin: 1rem auto 2rem;
        line-height: 1; }
      body #app .login .contenido-login .logo {
        margin-bottom: .5rem;
        height: auto; }
        body #app .login .contenido-login .logo svg {
          width: auto;
          height: 8rem; }
      body #app .login .contenido-login .error-msg {
        font-size: 1rem;
        margin-bottom: 1rem; }
      body #app .login .contenido-login form {
        display: flex;
        flex-flow: column nowrap;
        align-items: center; }
        body #app .login .contenido-login form .item {
          font-size: 1.5rem;
          padding: .5rem;
          text-align: center;
          border: 0;
          border-bottom: .1rem solid #dedede;
          margin-bottom: 1rem; }
        body #app .login .contenido-login form .submit {
          padding: 1.5rem 6rem;
          border: .1rem solid #666;
          font-size: 1.2rem;
          background: none;
          cursor: pointer;
          margin-top: 1.5rem;
          font-weight: bold;
          color: #666; }
          body #app .login .contenido-login form .submit:hover {
            color: #ededed;
            background: #666;
            border-color: #666; }
      body #app .login.vertical .contenido-login h1 {
        font-size: 5rem;
        text-transform: uppercase;
        margin: 2rem auto 4rem;
        line-height: 1; }
      body #app .login.vertical .contenido-login .logo {
        margin-bottom: 1rem; }
        body #app .login.vertical .contenido-login .logo svg {
          width: 50%; }
      body #app .login.vertical .contenido-login form {
        display: flex;
        flex-flow: column nowrap;
        align-items: center; }
        body #app .login.vertical .contenido-login form .item {
          font-size: 3rem;
          padding: 1.5rem;
          text-align: center;
          border: 0;
          border-bottom: .2rem solid #dedede;
          margin-bottom: 2rem; }
        body #app .login.vertical .contenido-login form .submit {
          padding: 1.5rem 6rem;
          border: .2rem solid #ededed;
          font-size: 3.5rem;
          background: none;
          cursor: pointer;
          margin-top: 3rem;
          font-weight: bold;
          color: #666; }
          body #app .login.vertical .contenido-login form .submit:hover {
            color: #ededed;
            background: #666;
            border-color: #666; }
