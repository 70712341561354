
@import 'variables';
@import 'mixins';

*{
    -webkit-user-select: none;
    /* Chrome all / Safari all */
    -moz-user-select: none;
    /* Firefox all */
    -ms-user-select: none;
    /* IE 10+ */
    user-select: none;
    /* Likely future */
}
html{
    
    font-size: 14px;
    font-family: $font;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding: 0;
    margin: 0;
    *:focus{
                outline: none;
            }
    body{
        margin: 0;
        padding: 0;
        font-family: $font;
        background: $color_fondo;
    }
    p{
        margin: 0;
        padding: 0;
    }
}

// Home
.home-mainWrapper{
    display: flex;
    width: 100vw;
    height: calc(100vh - 5vh);
    position: relative;
    justify-content: center;
    align-items: center;
    background: $color_fondo;
    &:focus{
        outline: none;
    } 
    > ul{
        margin: 0;
        padding: 0;
        width: 80%;
        // display: grid;
        // grid-template-columns: repeat(4, 1fr);
        // grid-gap: 3vh;
        display: flex;
        flex-flow: row wrap;
        > li{
            list-style: none;
            width: calc((100% - 80px) / 5);
            margin-right: 20px;
            margin-bottom: 20px;
            &:nth-child(5n){
                margin-right: 0;
            }

            .home-game-btn{
                text-decoration: none;
                background: #FFF;
                display: flex;
                flex-flow: column nowrap;
                height: 20vh;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 10px;
                -webkit-box-shadow: 0px 0px 22px 2px rgba(0,0,0,0.1);
                -moz-box-shadow: 0px 0px 22px 2px rgba(0,0,0,0.1);
                box-shadow: 0px 0px 22px 2px rgba(0,0,0,0.1);
                padding: 20px;
                box-sizing: border-box;
                width: 100%;
                border: none;
                cursor: pointer;
                .ico{
                    &:before{
                        font-size: 6vh;
                    }
                }
                p.title{
                    font-size: 2.6vh;
                    line-height: 2.6vh;
                    font-weight: bold;
                    color: #000;
                    margin-top: 20px;
                    text-align: center;
                    font-family: $font-bold;
                }
                &.nacional{ .ico{ &:before{ color: $color_nacional } } p.title{color: $color_nacional;} &:hover{background: $color_nacional; p.title{color: #FFF;} .ico{&:before{color: #FFF;}}}}
                &.botes{ .ico{ &:before{ color: $color_botes } } p.title{color: $color_botes;} &:hover{background: $color_botes; p.title{color: #FFF;} .ico{&:before{color: #FFF;}}}}
                &.bonoloto{ .ico{ &:before{ color: $color_bonoloto } } p.title{color: $color_bonoloto;} &:hover{background: $color_bonoloto; p.title{color: #FFF;} .ico{&:before{color: #FFF;}}}}
                &.primitiva{ .ico{ &:before{ color: $color_primitiva } } p.title{color: $color_primitiva;} &:hover{background: $color_primitiva; p.title{color: #FFF;} .ico{&:before{color: #FFF;}}}}
                &.euromillon{ .ico{ &:before{ position: relative; display: block; font-size: 9vh; margin-top: -26px; margin-left: 20px; color: $color_euromillon } } p.title{color: $color_euromillon;} &:hover{background: $color_euromillon; p.title{color: #FFF;} .ico{&:before{color: #FFF;}}}}
                &.gordo_primitiva{ .ico{ &:before{ color: $color_gordo_primitiva } } p.title{color: $color_gordo_primitiva;} &:hover{background: $color_gordo_primitiva; p.title{color: #FFF;} .ico{&:before{color: #FFF;}}}}
                &.quiniela{ .ico{ &:before{ color: $color_quiniela } } p.title{color: $color_quiniela;} &:hover{background: $color_quiniela; p.title{color: #FFF;} .ico{&:before{color: #FFF;}}}}
                &.quinigol{ .ico{ &:before{ color: $color_quinigol } } p.title{color: $color_quinigol;} &:hover{background: $color_quinigol; p.title{color: #FFF;} .ico{&:before{color: #FFF;}}}}
                &.lototurf{ .ico{ &:before{ color: $color_lototurf } } p.title{color: $color_lototurf;} &:hover{background: $color_lototurf; p.title{color: #FFF;} .ico{&:before{color: #FFF;}}}}
                &.quintupleplus{ .ico{ &:before{ color: $color_quintupleplus } } p.title{color: $color_quintupleplus;} &:hover{background: $color_quintupleplus; p.title{color: #FFF;} .ico{&:before{color: #FFF;}}}}
                &.clasificacion{ .ico{ &:before{ color: $color_clasificacion } } p.title{color: $color_clasificacion;} &:hover{background: $color_clasificacion; p.title{color: #FFF;} .ico{&:before{color: #FFF;}}}}
                &.el_ninyo{ .ico{ &:before{ color: $color_el_ninyo } } p.title{color: $color_el_ninyo;} &:hover{background: $color_el_ninyo; p.title{color: #FFF;} .ico{&:before{color: #FFF;}}}}
                &.eurodreams{ .ico{ &:before{ color: $color_eurodreams } } p.title{color: $color_eurodreams;} &:hover{background: $color_eurodreams; p.title{color: #FFF;} .ico{&:before{color: #FFF;}}}}
            }
        }
    }
}

// Footer
#footer{
    display: block;
    position: relative;
    height: 5vh;
    background: #000;   
    box-sizing: border-box;    
    .logo{
        display: block;
        position: absolute;
        left: 0;
        bottom: -5px;
        box-sizing: border-box;
        background: transparent;
        // background: $color_fondo;
        padding: 1vh;
        img{            
            max-height: 13vh;
        }
    }
    .texto{
        display: flex;
        width: 100%;
        position: relative;
        align-items: center;
        justify-content: center;
        padding: 0 300px 0 300px;
        height: 5vh;
        box-sizing: border-box;
        span{
            color: #fff;
            font-size: 2vh;
            letter-spacing: 1px;
            font-family: $font;
             /* Better Font Rendering =========== */
             -webkit-font-smoothing: antialiased;
             -moz-osx-font-smoothing: grayscale;
        }
    }
    .qr-code{
        display: block;
        position: absolute;
        background: $color_fondo;
        right: 0;
        bottom: -5px;
        width: auto;
        box-sizing: border-box;
        margin-right: 0px;
        padding: 5px;
        img {
            width: 100%;          
        }
        span{
            text-align: center;
            width: 100%;
            color: #000;
            font-size: 1.5vh;
            letter-spacing: 1px;
            font-family: $font;
            display: block;
            position: relative;
            padding-bottom: 5px;
            box-sizing: border-box;
             /* Better Font Rendering =========== */
             -webkit-font-smoothing: antialiased;
             -moz-osx-font-smoothing: grayscale;
        }
    }
    &.in{
        height: 4vh;
        .qr-code,
        .logo{
            display: none;
        }
        .texto{
            height: 4vh;
            span{
                font-size: 1.5vh;
            }
        }
    }
}

// Juegos
.game-header{
    display: flex;
    height: 9vh;
    background: #333;
    justify-content: space-between;
    align-items: center;
    flex-flow: row nowrap;
    padding: 0 20px;
    box-sizing: border-box;
    &:focus{
        outline: none;
    } 
    h1{
        font-size: 4vh;
        font-family: $font-bold;
        color: #FFF;
        margin: 0;
        padding: 0;
        position: relative;
        display: flex;
        align-items: center;
        .ico {
            &:before{
                font-size: 5vh;
                position: relative;
                left: 0;
                margin-right: 20px;
            }
            &.icon-euromillon {
                &:before {
                    display: block;
                    margin-top: -10px;
                    font-size: 7vh;
                }
            }
        }
    }
    > button{
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px 70px;
        box-sizing: border-box;
        background: #FFF;
        color: #333;
        height: 5vh;
        font-size: 2vh;
        text-align: center;
        border-radius: 5px;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-family: $font-bold;
        border: none;
        cursor: pointer;
        &:hover{
            color: #fff;
            background: #333;
        }
    }
    &.nacional{
        background: $color_nacional;
    }
    &.botes {
        background: $color_botes;
    }
    &.bonoloto{
        background: $color_bonoloto;
    }
    &.primitiva{
        background: $color_primitiva;
    }
    &.gordo_primitiva{
        background: $color_gordo_primitiva;
    }
    &.euromillon{
        background: $color_euromillon;
    }
    &.quiniela{
        background: $color_quiniela;
    }
    &.quinigol{
        background: $color_quinigol;
    }
    &.lototurf{
        background: $color_lototurf;
    }
    &.quintupleplus{
        background: $color_quintupleplus;
    }
    &.el_ninyo{
        background: $color_el_ninyo;
    }
    &.eurodreams{
        background: $color_eurodreams;
    }
    &.clasificacion {
        background: $color_clasificacion;
    }
}
.loading_img{
    position: relative;
    p{
        position: absolute;
        transform: translate(-50%,-50%);
        top: 50%;
        left: 50%;
    }
}
.games-mainWrapper{
    display: flex;
    flex-flow: row nowrap;
    height: calc(100vh - 13vh);
    background: $color_fondo;
    &:focus{
        outline: none;
    } 
    .left{
        width: 30vw;
        padding: 3vw 40px 3vw 70px;
        box-sizing: border-box;
        &:focus{
            outline: none;
        } 
        .date{
            flex-flow: column nowrap;
            p{
                font-size: 4.5vh;
            }
        }
        .numbers{
            margin-top: 3vh;
            .numbers-title{
                font-size: 2vh;
            }
            
            > ul{
                display: flex;
                flex-flow: row wrap;
                justify-content: flex-start;
                padding: 0;
                margin: 2vh 0 0 0;
                li:not(.special),
                li.special .number{
                    list-style: none;
                    background: #FFF;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 5vh;
                    font-weight: bold;
                    color: #333;
                    padding: 15px;
                    box-sizing: border-box;
                    border-radius: 5px;
                    margin-bottom: 5%;
                    font-family: $font-bold;

                    width: calc((100% - 60px) / 3);
                    margin-right: 30px;
                    &:nth-child(3n){
                        margin-right: 0;
                    }
                }
                
            }

        }
        .extra{
            margin-top: 3vh;
            > ul{
                display: flex;
                flex-flow: row wrap;
                justify-content: flex-start;
                padding: 0;
                margin: 0;
                li{
                    display: flex;
                    flex-flow: column nowrap;
                    width: calc((100% - 60px) / 3);
                    margin-right: 30px;
                    .title{
                        font-size: 2vh;
                        text-align: center;
                    }
                    .number{
                        list-style: none;
                        background: #FFF;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 5vh;
                        font-weight: bold;
                        color: #333;
                        padding: 15px;
                        box-sizing: border-box;
                        border-radius: 5px;
                        margin-top: 2vh;
                        font-family: $font-bold;
                    }
                }
            }
        }
        .botones{
            display: flex;
            flex-flow: column nowrap;
            margin-top: 5vh;
            .boton{
                text-decoration: none;
                background: #FFF;
                display: flex;
                flex-flow: column nowrap;
                height: 10vh;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 10px;
                box-shadow: 0px 0px 22px 2px rgba(0, 0, 0, 0.1);
                padding: 20px;
                box-sizing: border-box;
                width: 100%;
                border: none;
                margin-bottom: 3vh;
                cursor: pointer;
                .title{
                    font-size: 3vh;
                    line-height: 3vh;
                    font-weight: bold;
                    color: #951B81;
                    text-align: center;
                    font-family: $font-bold;                    
                }
                &.activo,
                &:hover {
                    background: #951B81;
                    .title{
                        color: #fff;
                    }
                }
            }
        }
    }
    @keyframes galleryappear {
        from {opacity: 0;}
        to {opacity: 1;}
    }
    .right{
        width: 70vw;
        height: 100%; 
        &:focus{
            outline: none;
        }  
        .imgsgallery{
            height: calc(100% - 100px);
            top: 0;
            // transform: translateY(-50%);
            overflow: hidden;
            opacity: 0;
            position: relative;
            &.slick-initialized{
                opacity: 1;
                animation-name: galleryappear;
                animation-duration: 1s;
            }
            .image-item{
                position: relative;
                > .ico{
                    text-decoration: none;
                    width: 7vh;
                    height: 7vh;
                    background: rgba(#000, .5);
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 5px;
                    &:before{
                        font-size: 40px;
                        color: #FFF;
                    }
                }
            }
            .slick-list{
                height: 100%;
                width: 100%;
                box-sizing: border-box;
                .slick-track{
                    height: 100%;
                    .slick-slide{
                        height: 100%;
                        opacity: 0;
                        transition: opacity .6s;
                        padding: 18px 0;
                        box-sizing: border-box;
                        float: left;
                        min-height: 1px;
                        &.slick-current{
                            opacity: 1;
                            transition: opacity .6s;
                        }
                        > div{
                            height: 100%;
                            -webkit-box-shadow: 0px 0px 23px -5px rgba(0,0,0,0.3);
                            -moz-box-shadow: 0px 0px 23px -5px rgba(0,0,0,0.3);
                            box-shadow: 0px 0px 23px -5px rgba(0,0,0,0.3);
                        }
                    }                    
                }
            }
        }
        .react_lightgallery_item{
            height: 100%;
            position: relative;            
            display: flex;
            justify-content: center;            
            box-sizing: border-box;
            overflow: hidden;            
            .icon-zoom{
                position: absolute;
                // bottom: 40px;
                // right: 40px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 130px;
                height: 130px;
                background: rgba(#000, .6);
                display: flex;
                justify-content: center;
                align-items: center;
                border: none;
                border-radius: 5px;
                &:before{
                    font-size: 7.5vh;
                    color: #FFF;
                    /* Better Font Rendering =========== */
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                }
            }
            img{
                height: 100%;
                object-fit: cover;
                &.img_error{
                    width: 100%;
                }
            }
        }
        &.single{
            .react_lightgallery_item{
                padding: 18px 0;
                height: calc(100% - 100px);
                top: 0;
                overflow: hidden;
                position: relative;
                img{
                    box-shadow: 0px 0px 23px -5px rgba(0, 0, 0, 0.3);
                }
            }
            &.full{
                width: 100%;
            }
            &.full_h{
                .react_lightgallery_item {
                    height: 100%;
                }
                .imgsgallery{
                    height: 100%;
                }
            }
        }
        &.multiple{
            &.full_h{
                .react_lightgallery_item {
                    height: 100%;
                }
                .imgsgallery{
                    height: 100%;
                }
            }
            &.full_w{
                width: 100%;
            }
        }
        .input-calendar-wrapper{
            height: 100px;
            display: flex;
            justify-content: center;
            padding-top: 10px;
            box-sizing: border-box;
            .date-button {
                display: flex;
                flex-flow: row nowrap;
                justify-content: center;
                align-items: center;
                width: 60px;
                height: 60px;
                margin: 0;
                padding: 10px;
                box-sizing: border-box;
                font-family: $font;
                font-weight: bold;
                font-size: 0;
                color: #FFF;
                background: rgba(#000, .6);
                border: none;
                cursor: pointer;
                &:focus{
                    outline: none;
                }
                &:before{
                    font-family: $font-icons;
                    color: #FFF;
                    font-size: 3vh;
                    line-height: 1;
                }
                &.date-prev{
                    margin: 0 15px 0 0;
                    &:before{
                        content: "\e909";
                    }
                }
                &.date-next {
                    margin: 0 0 0 15px;
                    &:before{
                        content: "\e905";
                    }
                }
            }
            .input-calendar{
                width: 60%;
                display: flex;
                flex-flow: row nowrap;
                .text-input-day-wrapper{
                    border: 1px solid #CCC;
                    background: #FFF;
                    width: 100%;
                    height: 60px;
                    padding: 5px 15px;
                    box-sizing: border-box;
                    font-family: $font;
                    font-size: 2vh;
                    width: 60%;
                    display: flex;
                    align-items: center;
                    &:focus{
                        outline: none;
                    }
                }
                > button{
                    width: 40%;
                    font-family: $font;
                    font-weight: bold;
                    font-size: 2vh;
                    height: 60px;
                    color: #FFF;
                    background: $color_euromillon;
                    border: 1px solid $color_euromillon;
                    cursor: pointer;
                    &:focus{
                        outline: none;
                    }
                }
            }
        }
    }
    &.nacional{
        .right .input-calendar-wrapper .input-calendar > button{
            background: $color_nacional;
            border: 1px solid $color_nacional;
        }
    }
    &.botes{
        .right .input-calendar-wrapper .input-calendar > button{
            background: $color_botes;
            border: 1px solid $color_botes;
        }
    }
    &.quinigol{
        .right .input-calendar-wrapper .input-calendar > button{
            background: $color_quinigol;
            border: 1px solid $color_quinigol;
        }
    }
    &.lototurf{
        .right .input-calendar-wrapper .input-calendar > button{
            background: $color_lototurf;
            border: 1px solid $color_lototurf;
        }
    }
    &.quintupleplus{
        .right .input-calendar-wrapper .input-calendar > button{
            background: $color_quintupleplus;
            border: 1px solid $color_quintupleplus;
        }
    }
    &.lototurf{
        .right .input-calendar-wrapper .input-calendar > button{
            background: $color_lototurf;
            border: 1px solid $color_lototurf;
        }
    }
    &.primitiva{
        .right .input-calendar-wrapper .input-calendar > button{
            background: $color_primitiva;
            border: 1px solid $color_primitiva;
        }
    }
    &.gordo_primitiva{
        .right .input-calendar-wrapper .input-calendar > button{
            background: $color_gordo_primitiva;
            border: 1px solid $color_gordo_primitiva;
        }
    }
    &.quiniela{
        .right .input-calendar-wrapper .input-calendar > button{
            background: $color_quiniela;
            border: 1px solid $color_quiniela;
        }
    }
    &.bonoloto{
        .right .input-calendar-wrapper .input-calendar > button{
            background: $color_bonoloto;
            border: 1px solid $color_bonoloto;
        }
    }
    &.clasificacion{
        .right .input-calendar-wrapper .input-calendar > button{
            background: $color_clasificacion;
            border: 1px solid $color_clasificacion;
        }
    }
    &.euromillon{
        .right .input-calendar-wrapper .input-calendar > button{
            background: $color_euromillon;
            border: 1px solid $color_euromillon;
        }
    }
    &.el_ninyo{
        .right .input-calendar-wrapper .input-calendar > button{
            background: $color_el_ninyo;
            border: 1px solid $color_el_ninyo;
        }
    }
    &.eurodreams{
        .right .input-calendar-wrapper .input-calendar > button{
            background: $color_eurodreams;
            border: 1px solid $color_eurodreams;
        }
    }
    .oculto{
        display: none;
    }
}
.games-mainWrapper.primitiva{
    .left{
        .joker{
            margin-top: 4vh;
            .title{
                font-size: 3vh;
                font-family: $font-bold;
                color: $color_gordo_primitiva;
                text-align: center;
            }
            .number{
                list-style: none;
                background: #FFF;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 5vh;
                font-family: $font-bold;
                color: #333;
                padding: 15px;
                box-sizing: border-box;
                border-radius: 5px;
                margin-top: 1vh;
            }
        }
    }
}
.games-mainWrapper.quiniela{
    .left{
        .jornada{
            color: $color_quiniela;
            font-size: 3vh !important;
        }
        .numbers-quiniela-equipos{
            margin-top: 3vh;
            
            > ul{
                padding: 0;
                margin: 3vh 0 0 0;
                li{
                    list-style: none;
                    display: flex;
                    flex-flow: row nowrap;
                    margin-bottom: 10px;
                    p{
                        font-size: 2vh;
                    }
                    .index{
                        width: 15%;
                    }
                    .teams{
                        width: 70%;
                    }
                    .value{
                        width: 15%;
                        text-align: center;
                        font-size: 3vh;
                        font-family: $font-bold;
                    }
                }
            }
        }
        .numbers-quiniela{
            margin-top: 3vh;
            > ul{
                padding: 0;
                margin: 0;
                display: flex;
                flex-flow: row nowrap;
                .col{
                    width: 50%;
                    padding: 0 20px;
                    box-sizing: border-box; 
                    p{
                        margin: 0;
                    }
                }
                li.line{
                    list-style: none;
                    display: flex;
                    flex-flow: row nowrap;
                    align-items: center;
                    margin-bottom: 2vh;
                    .index{
                        width: 25%;
                        font-size: 2vh;
                    }
                    .value{
                        width: 75%;
                        list-style: none;
                        background: #FFF;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 4vh;
                        font-family: $font-bold;
                        color: #333;
                        padding: 5px 15px;
                        box-sizing: border-box;
                        border-radius: 5px;
                    }
                    &.pleno{
                        .value{
                            white-space: nowrap
                        }
                    }
                }
            }
        }
    }
}
.games-mainWrapper.quinigol{
    .left{
        .jornada{
            color: $color_quiniela;
            font-size: 3vh !important;
        }
        .numbers-quinigol{
            margin-top: 4vh;
            > ul{
                padding: 0;
                margin: 0;
                display: flex;
                flex-flow: row nowrap;
                .col{
                    width: 200px;
                    padding: 0;
                    box-sizing: border-box; 
                    p{
                        margin: 0;
                    }
                }
                li.line{
                    list-style: none;
                    display: flex;
                    flex-flow: row nowrap;
                    align-items: center;
                    margin-bottom: 3vh;
                    .index{
                        width: 25%;
                        font-size: 2vh;
                    }
                    .value{
                        width: 75%;
                        list-style: none;
                        background: #FFF;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 4vh;
                        font-family: $font-bold;
                        color: #333;
                        padding: 5px 15px;
                        box-sizing: border-box;
                        border-radius: 5px;
                    }
                    &.pleno{
                        .value{
                            white-space: nowrap
                        }
                    }
                }
            }
        }
    }
}
.games-mainWrapper.quintupleplus{
    .left{
        .numbers-quintupleplus{
            margin-top: 4vh;
            .numbers-title{
                font-size: 2vh;
            }
            > ul{
                padding: 0;
                margin: 0;
                display: flex;
                flex-flow: row nowrap;
                margin-top: 3vh;
                .col{
                    width: 100%;
                    padding: 0;
                    box-sizing: border-box; 
                    p{
                        margin: 0;
                    }
                }
                li.line{
                    list-style: none;
                    display: flex;
                    flex-flow: row nowrap;
                    align-items: center;
                    margin-bottom: 3vh;
                    .index{
                        width: calc(100% - 100px);
                        font-size: 2vh;
                    }
                    .value{
                        width: 100px;
                        list-style: none;
                        background: #FFF;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 4vh;
                        font-family: $font-bold;
                        color: #333;
                        padding: 5px 15px;
                        box-sizing: border-box;
                        border-radius: 5px;
                    }
                    &.pleno{
                        .value{
                            white-space: nowrap
                        }
                    }
                }
            }
        }
    }
}
.games-mainWrapper.euromillon{
    .left{
        .extra{
            margin-top: 3vh;
            > ul{
                display: flex;
                flex-flow: row wrap;
                justify-content: flex-start;
                padding: 0;
                margin: 0;
                li{
                    display: flex;
                    flex-flow: column nowrap;
                    width: calc((100% - 60px) / 3);
                    margin-right: 30px;
                    .title{
                        font-size: 2vh;
                        text-align: center;
                    }
                    .number{
                        list-style: none;
                        background: #FFF;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 5vh;
                        font-weight: bold;
                        color: #333;
                        padding: 15px;
                        box-sizing: border-box;
                        border-radius: 5px;
                        margin-top: 2vh;
                        font-family: $font-bold;
                    }
                    &.estrella{
                        .ico{
                            display: flex;
                            justify-content: center;
                            &:before{
                                font-size: 4vh;
                                color: #f5bc41;
                            }
                        }
                    }
                }
            }
        }
        .el_millon{
            margin-top: 4vh;
            .title{
                font-size: 3vh;
                font-family: $font-bold;
                color: $color_gordo_primitiva;
                text-align: center;
            }
            .number{
                list-style: none;
                background: #FFF;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 5vh;
                font-family: $font-bold;
                color: #333;
                padding: 15px;
                box-sizing: border-box;
                border-radius: 5px;
                margin-top: 1vh;
            }
        }
    }
}
.games-mainWrapper.nacional{
    .left{
        .numbers{
            .nacional-number-wrapper{
                display: flex;
                flex-flow: row nowrap;
                &.primero{
                    display: flex;
                    flex-flow: column nowrap;
                    align-items: flex-start;
                    justify-content: space-between;
                    .primero-number{
                        width: 100%;

                        >.numbers-title{
                            font-family: $font-bold;
                            color: $color_nacional;
                        }
                        > ul{
                            margin-top: 0;
                            li{
                                width: 100%;
                                padding: 0;
                                background: transparent;
                                justify-content: flex-start;
                                color: $color_nacional;
                                font-size: 6vh;
                                margin: 0;
                            }
                        }
                    }                    
                    .primero-special{
                        width: 100%;
                        margin-top: 1vh;
                        > .title{
                            font-size: 1.5vh;
                            display: none;
                        }
                        > ul{
                            margin-top: 0;
                            display: flex;
                            flex-flow: row wrap;
                            justify-content: flex-start;
                            li{
                                list-style: none;
                                position: relative;
                                display: flex;
                                flex-flow: row nowrap;
                                align-items: center;
                                .title{
                                    // position: absolute;
                                    top: 0;
                                    font-size: 1.5vh;
                                }
                                .number{
                                    background: none;
                                    padding: 0 15px;
                                    font-size: 5vh;
                                }
                                &:first-of-type{
                                    margin-right: 20px;
                                }
                            }
                        }
                    }
                }
                &.el_ninyo {
                    .primero-number{
                        >.numbers-title {
                            color: $color_el_ninyo;
                        }
                        >ul {
                            li {
                                color: $color_el_ninyo;
                            }
                        }
                    }
                }
                &.others{
                    display: flex;
                    flex-flow: row wrap;
                    justify-content: flex-start;
                    margin-top: 3vh;
                    .number-wrapper{
                        &.second{
                            margin-right: 50px;
                        }
                        > ul{
                            margin-top: 1vh;
                            li{
                                width: 100%;
                                padding: 0;
                                background: transparent;
                                justify-content: flex-start;
                            }
                        }
                    }
                }
                ul{
                    display: flex;
                    flex-flow: row wrap;
                    justify-content: space-between;
                    padding: 0;
                    margin: 2vh 0 0 0;
                    li:not(.special),
                    li.special .number{
                        list-style: none;
                        background: #FFF;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 5vh;
                        font-weight: bold;
                        color: #333;
                        padding: 15px;
                        box-sizing: border-box;
                        border-radius: 5px;
                        margin-bottom: 5%;
                        font-family: $font-bold;
                    }
                }
                &.fourth{
                    width: 100%;
                    .number-wrapper{
                        width: 100%;
                        ul{
                            border-radius: 5px;
                            overflow: hidden;
                            li{
                                width: 50%;
                                margin-bottom: 0;
                                font-size: 4vh;
                                padding: 12px;
                                border-radius: 0;
                            }
                        }
                    }
                }
                &.fifth {
                    width: 100%;
                    margin-top: 20px;
                    .number-wrapper {
                        ul {
                            border-radius: 5px;
                            overflow: hidden;
                            li {
                                width: 50%;
                                margin-bottom: 0;
                                font-size: 3vh;
                                padding: 10px;
                                border-radius: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

// General
.loading-mainWrapper{
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    background: $color_fondo;
    height: calc(100vh - 4vh);
    .message{
        font-size: 3vh;
        font-family: $font-bold;
        color: $color_nacional;
        text-align: center;
        &.primitiva{
            color: $color_primitiva;
        }
        &.quiniela {
            color: $color_quiniela;
        }
        &.quinigol {
            color: $color_quinigol;
        }
        &.quintupleplus {
            color: $color_quintupleplus;
        }
        &.euromillon {
            color: $color_euromillon;
        }
        &.nacional {
            color: $color_nacional;
        }
        &.bonoloto {
            color: $color_bonoloto;
        }
        &.gordo_primitiva {
            color: $color_gordo_primitiva;
        }
        &.lototurf {
            color: $color_lototurf;
        }
        &.el_ninyo{
            color: $color_el_ninyo;
        }
        &.eurodreams{
            color: $color_eurodreams;
        }
        &.botes {
            color: $color_botes;
        }
        &.clasificacion {
            color: $color_clasificacion;
        }        
    }
}
.days-calendar{
    position: absolute;
    width: 100vw;
    height: 100vh;
    background: rgba(#000, .6);
    z-index: 99999;
    .calendar-wrapper{
        position: absolute;
        top: 50%;
        left: 50%;
        background: #FFF;
        transform: translate(-50%, -50%);
        width: 55vw;
        height: 60vh;
        min-width: 700px;
        .btn-open-calendar{
            background: none;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 3vh;
            height: 3vh;
            background: #FFF;
            margin: 0;
            top: 1vh;
            left: initial;
            right: 2vh;
            border: none;
            position: absolute;
            cursor: pointer;
            z-index: 99;
            &:focus{
                outline: none;
            }
            span:before{
                color: #000;
                font-size: 3vh;
            }
        }
        .DayPicker{
            width: 100%;
            height: 100%;

            &.bonoloto{ .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside){ background-color: $color_bonoloto; } }
            &.euromillon{ .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside){ background-color: $color_euromillon; } }
            &.primitiva{ .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside){ background-color: $color_primitiva; } }
            &.quiniela{ .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside){ background-color: $color_quiniela; } }
            &.gordo_primitiva{ .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside){ background-color: $color_gordo_primitiva; } }
            &.quinigol{ .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside){ background-color: $color_quinigol; } }
            &.lototurf{ .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside){ background-color: $color_lototurf; } }
            &.quintupleplus{ .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside){ background-color: $color_quintupleplus; } }
            &.eurodreams{ .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside){ background-color: $color_eurodreams; } }
            
            .DayPicker-wrapper{
                width: 100%;
                height: 100%;
                position: relative;
                padding: 0;
                .DayPicker-NavBar{
                    position: absolute;
                    top: calc(50% - 2.5vh);
                    width: 100%;
                    .DayPicker-NavButton{
                        background: none;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 5vh;
                        height: 5vh;
                        background: #000;
                        margin: 0;
                        top: 0;
                        left: 2vh;
                        right: initial;
                        &:focus{
                            outline: none;
                        }
                        &:before{
                            content: "\e909";
                            font-family: $font-icons;
                            color: #FFF;
                            font-size: 3vh;
                        }
                        &.DayPicker-NavButton--next{
                            right: 2vh;
                            left: initial;
                            &:before{
                                content: "\e905";
                            }
                        }
                    }
                }
                .DayPicker-Months{
                    height: 100%;
                    align-items: center;
                    .DayPicker-Month{
                        width: 70%;
                        height: 70%;
                        margin: 0;
                        position: relative;
                        .DayPicker-Caption{
                            height: 5vh;
                            margin-bottom: 3%;
                            font-size: 3vh;
                            font-family: $font-bold;
                            position: relative;
                            > div{
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                            }
                        }
                        .DayPicker-Weekdays{
                            .DayPicker-Weekday{
                                font-size: 2.2vh;
                            }
                        }
                        .DayPicker-Body{
                            .DayPicker-Day{
                                font-size: 3.2vh;
                                width: calc(100% / 7);
                                border-radius: 0;
                                border: 7px solid #FFF;
                                &:focus{
                                    outline: none;
                                }
                            }
                            .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside){
                                // background-color: transparent;
                                // position: relative;
                                // display: grid;

                            }
                        }
                        
                    }
                }
            }
        }
    }
}
.lg-backdrop{
    z-index: 9998 !important;
}
.lg-outer{
    z-index: 9999 !important;
}
.slick-arrow {
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 2px;
    border: none;
    cursor: pointer;
    font-size: 0;
    line-height: 0;
    margin-top: -10px;
    padding: 8px 10px 9px;
    position: absolute;
    top: 50%;
    z-index: 1080;
    text-decoration: none;
    left: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:before{
        content: "\e905";
        font-family: $font-icons;
        color: #FFF;
        font-size: 3vh;
        line-height: 1;
    }
    &.slick-prev{
        &:before{
            content: "\e909";
        }
    }
    &:focus{
        outline: none;
    }
    span:before{
        color: #FFF;
    }
    &.slick-disabled{
        opacity: .3;
    }
    &.slick-next{
        left: initial;
        right: 50px;
    }
}
.lg-next, .lg-prev{
    font-size: 4vh !important;
    color: #FFF !important;
    opacity: 1 !important;
    transform: none !important;
    &.disabled{
        opacity: .3 !important;
    }
}

.lg-toolbar{
    opacity: 1 !important;
    transform: none !important;
    width: auto;
    top: 3vh;
    right: 3vh;
    left: initial;
    .lg-icon{
        font-size: 5vh !important;
        width: 7vh;
        height: 7vh;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff !important;
        &#lg-actual-size{
            font-size: 4vh !important;
        }
    }
}

.picker{
    line-height: 2;
    font-size: 20px;
    font-weight: 400;
}
.picker__day{
    font-weight: 400;
}
.picker__frame{
    max-width: 50vw;
}
.picker__footer{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    margin-bottom: 15px;
    button[class*="picker__button"]{
        border: 1px solid #999;
        width: 45%;
        border-radius: 10px;
    }
}
.picker--opened .picker__frame{
    top: 50%;
    bottom: initial;
    height: auto;
    transform: translateY(-50%);
}
.picker__nav--prev,
.picker__nav--next{
    display: flex;
    align-items: center;
    justify-content: center;
    &:before{
        font-family: $font-icons;
        content: "\e906";
        border: none;
        font-size: 50px;
        width: auto;
        height: auto;
        margin: 0;
    }
}
.picker__nav--prev:before{
    content: "\e90a";
}


@include querie(1300px) {
    .games-mainWrapper .left{
        padding-left: 30px;
    }
    .games-mainWrapper .left .date p{
        font-size: 3.5vh;
    }
    .games-mainWrapper .left .numbers > ul li:not(.special), 
    .games-mainWrapper .left .numbers > ul li.special .number,
    .games-mainWrapper .left .extra > ul li{
        width: calc((100% - 40px) / 3);
        margin-right: 20px;
    }  
}

@include querie(1024px) {
    .games-mainWrapper .left .numbers>ul li:not(.special),
    .games-mainWrapper .left .numbers>ul li.special .number,
    .games-mainWrapper .left .extra>ul li{
        width: calc((100% - 30px) / 3);
        margin-right: 15px;
    }
    .games-mainWrapper.quiniela .left .numbers-quiniela>ul .col {
        padding: 0 15px;
    }
    .games-mainWrapper.quiniela .left .numbers-quiniela>ul li.line .index{
        width: 20%;
    }
    .games-mainWrapper.quiniela .left .numbers-quiniela>ul li.line .value{
        width: 80%;
    }
    .games-mainWrapper .left {
        padding-left: 20px;
        padding-right: 30px;
    }
    .games-mainWrapper .right.single .react_lightgallery_item{
        height: calc(100% - 15vh);
    }
    .games-mainWrapper .right .input-calendar-wrapper{
        height: 15vh;
    }
    .games-mainWrapper .right .input-calendar-wrapper .input-calendar{
        width: 70%;
    }
    .games-mainWrapper .right .input-calendar-wrapper .input-calendar .text-input-day-wrapper,
    .games-mainWrapper .right .input-calendar-wrapper .input-calendar>button{
        height: 9vh;
    }
    .games-mainWrapper.nacional .left .numbers .nacional-number-wrapper.fourth{
        margin-top: 5px;
    }
    .games-mainWrapper.nacional .left .numbers .nacional-number-wrapper ul,
    .games-mainWrapper.nacional .left .numbers .nacional-number-wrapper.fourth .number-wrapper ul{
        margin-top: 5px;
    }
    .games-mainWrapper.nacional .left .numbers .nacional-number-wrapper.fifth{
        margin-top: 15px;
    }
    .games-mainWrapper.nacional .left .numbers .nacional-number-wrapper.fifth .number-wrapper ul li{
        padding: 8px;
    }
}

@include querie(920px) {
   .home-mainWrapper>ul{
       width: 90%;
   }
   .games-mainWrapper.quintupleplus .left .numbers-quintupleplus>ul li.line .value{
       width: 70px;
   }
}

@include querie(640px) {
    .home-mainWrapper {
        padding: 10px 0;
    }
    .home-mainWrapper>ul>li {
        width: calc((100% - 30px) / 3);
        margin-right: 15px;
        margin-bottom: 15px;
    }
    .game-header{
        height: 10vh;
        padding: 0 30px;
    }
    .game-header h1{
        font-size: 3vh;
    }
    .game-header>button{
        padding: 0px 50px;
    }

    .home-mainWrapper>ul>li:nth-child(4n){
        margin-right: 15px;
    }
    .home-mainWrapper>ul>li:nth-child(3n) {
        margin-right: 0px;
    }
    .games-mainWrapper .left{
        width: 40vw;
    }
    .games-mainWrapper .right {
        width: 60vw;
    }
}


@include querie(572px) {
    .home-mainWrapper>ul>li .home-game-btn p.title {
        margin-top: 10px;
    }
}

@include querie(420px) {
    body #app{
        overflow: auto !important;
    }
    .home-mainWrapper{
        height: auto;
        // padding: 25px 0 15px 0;
    }
    .home-mainWrapper>ul>li {
        width: calc((100% - 20px) / 2);
        margin-right: 10px;
        margin-bottom: 10px;
    }
    .home-mainWrapper>ul>li:nth-child(2n) {
        margin-right: 0;
    }
    .home-mainWrapper>ul>li:nth-child(4n){
        margin-right: 0;
    }
    .home-mainWrapper>ul>li:nth-child(3n) {
        margin-right: 10px;
    }
    .home-mainWrapper>ul{
        padding-top: 4%;
    }
    .game-header>button {
        padding: 0px 25px;
        height: 6vh;
    }
}

