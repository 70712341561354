
@import 'variables';



html{
	font-size: 100%;
	-webkit-box-sizing: border-box;
    margin:0; padding:0; height:100%;
     background: #fff;
}

body{
	margin:0; padding:0; height:100%;
	-webkit-backface-visibility: hidden;
   	-moz-backface-visibility: hidden;
   	backface-visibility: hidden;
   	-webkit-box-sizing: border-box;
   	box-sizing: border-box;
   	overflow: hidden;
	#app{
		display: block;
		position: relative;
		overflow: hidden;
		margin: 0;
		padding: 0;
		height: 100%;
		.error-msg{
			font-family: $font;
			font-size: 2rem;
		    color: red;
		    text-align: center;
		    display: flex;
		    justify-content: center;
		    align-items: center;
		    margin: 0 auto;
		    height: 100%;
		}
		.loading-msg{
			display: flex;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: #fff;
			z-index: 9999;
			justify-content:center;
			align-items:center;
			flex-direction: column;
			img{
				width: 9rem;
			}
			span{
				font-family: $font;
				font-size: 4rem;
			    margin-bottom: 2rem;
			    color: #aaa;
			    text-align: center;
			}
		}
		#capa_offline{
			position: absolute;
		    top: 0;
		    bottom: 0;
		    left: 0;
		    right: 0;
		    display: flex;
		    justify-content:center;
			align-items:center;
		    z-index: 99998;
		    background: rgba(0,0,0,0.3);
		    span{
		    	color: red;
		    	font-size: 3rem;
		    	font-family: $font;
		    	font-weight: bold;
		    }
		}
		#capa_cargando{
			display: flex;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: #fff;
			z-index: 9999;
			justify-content:center;
			align-items:center;
			flex-direction: column;
			img{
				width: 9rem;
			}
			span{
				font-family: $font;
				font-size: 4rem;
			    margin-bottom: 2rem;
			    color: #aaa;
			    text-align: center;
			}
		}
		#logout{
			display: block;
		    position: absolute;
		    bottom: 0;
		    left: 0;
		    z-index: 99999;
		    width: 7rem;
		    height: 7rem;
		    border: 0;
		    background: transparent;
		    cursor: pointer;
		    margin: 0;
		    padding: 0;
		}
		#reload{
			display: block;
		    position: absolute;
		    bottom: 0;
		    right: 0;
		    z-index: 99999;
		    width: 7rem;
		    height: 7rem;
		    border: 0;
		    background: transparent;
		    cursor: pointer;
		    margin: 0;
		    padding: 0;
		}
		.login{
			text-align: center;
            font-family: $font;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
				.contenido-login{
				h1{					
					font-size: 2rem;
				    text-transform: uppercase;
				    margin: 1rem auto 2rem;
				    line-height: 1;
				}
				.logo{					
					margin-bottom: .5rem;
					height: auto;
					svg{
						width: auto;
						height: 8rem;
					}
				}
				.error-msg{
					font-size: 1rem;
				    margin-bottom: 1rem;
				}				
				form{
					display: flex;
					flex-flow: column nowrap;
					align-items: center;
					.item{
						font-size: 1.5rem;
					    padding: .5rem;
					    text-align: center;
					    border: 0;
					    border-bottom: .1rem solid #dedede;
					    margin-bottom: 1rem;
					}
					.submit{
						padding: 1.5rem 6rem;
					    border: .1rem solid #666;
					    font-size: 1.2rem;
					    background: none;
					    cursor: pointer;
					    margin-top: 1.5rem;
					    font-weight: bold;
					    color: #666;
					    &:hover{
					    	color: #ededed;
					    	background: #666;
					    	border-color: #666; 
					    }
					}
				}
			
			}
			&.vertical{
				.contenido-login{
					h1{					
						font-size: 5rem;
					    text-transform: uppercase;
					    margin: 2rem auto 4rem;
					    line-height: 1;
					}
					.logo{					
						margin-bottom: 1rem;
						svg{
							width: 50%;
						}
					}				
					form{
						display: flex;
						flex-flow: column nowrap;
						align-items: center;
						.item{
							font-size: 3rem;
						    padding: 1.5rem;
						    text-align: center;
						    border: 0;
						    border-bottom: .2rem solid #dedede;
						    margin-bottom: 2rem;
						}
						.submit{
							padding: 1.5rem 6rem;
						    border: .2rem solid #ededed;
						    font-size: 3.5rem;
						    background: none;
						    cursor: pointer;
						    margin-top: 3rem;
						    font-weight: bold;
						    color: #666;
						    &:hover{
						    	color: #ededed;
						    	background: #666;
						    	border-color: #666; 
						    }
						}
					}
				}
			}
        }
    }
}