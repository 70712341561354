// 1- COLORS -----------------------------------------
$color_fondo: #f1f1f1;

$color_nacional: #007ac0;
$color_botes: #1e9857;
$color_quinigol: #40BACE;
$color_lototurf: #d16929;
$color_quintupleplus: #eab842;
$color_primitiva: #008547;
$color_euromillon: #001367;
$color_gordo_primitiva: #dc3f47;
$color_quiniela: #cb1517;
$color_bonoloto: #7a8634;
$color_clasificacion: #951B81;
$color_el_ninyo: #a47311;
$color_eurodreams: #6B3E98;



// 2- MEDIA QUERIES -----------------------------------------
$desktop-xl: 1920px;
$desktop-l: 1600px;
$desktop-m: 1366px;
$desktop-s: 1280px;
$desktop-xs: 1095px;
$tablet: 1024px;
$movil-xxxl: 900px;
$movil-xxl: 768px;
$movil-xl: 750px;
$movil-l: 414px;
$movil: 320px;
$q900: 900px;
$q650: 650px;
$q550: 550px;
$q500: 500px;

$site_max_w: $desktop-xl;
$padding-tablet: 35px;
$padding-movil: 25px;

@mixin querie($size) {
  @media (max-width: $size) { @content; }
}

@mixin queriemin($size){
  @media (min-width: $size) { @content; }
}


// 3- FONTS -----------------------------------------
// @import url('https://fonts.googleapis.com/css?family=Montserrat:100,400,600,800');

/* montserrat-100 - latin */
@font-face {
  font-family: 'Montserrat-light';
  font-style: normal;
  font-weight: 100;
  src: url('../fonts/Montserrat/montserrat-v13-latin-100.eot'); /* IE9 Compat Modes */
  src: local('Montserrat Thin'), local('Montserrat-Thin'),
       url('../fonts/Montserrat/montserrat-v13-latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/Montserrat/montserrat-v13-latin-100.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/Montserrat/montserrat-v13-latin-100.woff') format('woff'), /* Modern Browsers */
       url('../fonts/Montserrat/montserrat-v13-latin-100.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/Montserrat/montserrat-v13-latin-100.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/Montserrat/montserrat-v13-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
       url('../fonts/Montserrat/montserrat-v13-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/Montserrat/montserrat-v13-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/Montserrat/montserrat-v13-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/Montserrat/montserrat-v13-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/Montserrat/montserrat-v13-latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-800 - latin */
@font-face {
  font-family: 'Montserrat-bold';
  font-style: normal;
  font-weight: 800;
  src: url('../fonts/Montserrat/montserrat-v13-latin-800.eot'); /* IE9 Compat Modes */
  src: local('Montserrat ExtraBold'), local('Montserrat-ExtraBold'),
       url('../fonts/Montserrat/montserrat-v13-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/Montserrat/montserrat-v13-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/Montserrat/montserrat-v13-latin-800.woff') format('woff'), /* Modern Browsers */
       url('../fonts/Montserrat/montserrat-v13-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/Montserrat/montserrat-v13-latin-800.svg#Montserrat') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?onsvam');
  src:  url('../fonts/icomoon.eot?onsvam#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?onsvam') format('truetype'),
    url('../fonts/icomoon.woff?onsvam') format('woff'),
    url('../fonts/icomoon.svg?onsvam#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}



[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.icon-eurodreams_color .path1:before {
  content: "\e92b";
  color: rgb(105, 46, 135);
}
.icon-eurodreams_color .path2:before {
  content: "\e92c";
  margin-left: -0.9951171875em;
  color: rgb(230, 45, 105);
}
.icon-eurodreams_color .path3:before {
  content: "\e92d";
  margin-left: -0.9951171875em;
  color: rgb(230, 45, 105);
}
.icon-eurodreams_color .path4:before {
  content: "\e92e";
  margin-left: -0.9951171875em;
  color: rgb(230, 45, 105);
}
.icon-eurodreams_color .path5:before {
  content: "\e92f";
  margin-left: -0.9951171875em;
  color: rgb(230, 45, 105);
}
.icon-eurodreams_color .path6:before {
  content: "\e930";
  margin-left: -0.9951171875em;
  color: rgb(230, 45, 105);
}
.icon-eurodreams_color .path7:before {
  content: "\e931";
  margin-left: -0.9951171875em;
  color: rgb(230, 45, 105);
}
.icon-eurodreams_color .path8:before {
  content: "\e932";
  margin-left: -0.9951171875em;
  color: rgb(43, 188, 238);
}
.icon-eurodreams_color .path9:before {
  content: "\e933";
  margin-left: -0.9951171875em;
  color: rgb(43, 188, 238);
}
.icon-eurodreams_color .path10:before {
  content: "\e934";
  margin-left: -0.9951171875em;
  color: rgb(105, 46, 135);
}
.icon-eurodreams_color .path11:before {
  content: "\e935";
  margin-left: -0.9951171875em;
  color: rgb(105, 46, 135);
}
.icon-eurodreams_color .path12:before {
  content: "\e936";
  margin-left: -0.9951171875em;
  color: rgb(68, 175, 110);
}
.icon-eurodreams_color .path13:before {
  content: "\e937";
  margin-left: -0.9951171875em;
  color: rgb(68, 175, 110);
}
.icon-eurodreams_color .path14:before {
  content: "\e938";
  margin-left: -0.9951171875em;
  color: rgb(236, 112, 162);
}
.icon-eurodreams_color .path15:before {
  content: "\e939";
  margin-left: -0.9951171875em;
  color: rgb(236, 112, 162);
}
.icon-eurodreams_color .path16:before {
  content: "\e93a";
  margin-left: -0.9951171875em;
  color: rgb(236, 112, 162);
}
.icon-eurodreams_color .path17:before {
  content: "\e93b";
  margin-left: -0.9951171875em;
  color: rgb(236, 112, 162);
}
.icon-eurodreams_color .path18:before {
  content: "\e93c";
  margin-left: -0.9951171875em;
  color: rgb(239, 126, 88);
}
.icon-eurodreams_color .path19:before {
  content: "\e93d";
  margin-left: -0.9951171875em;
  color: rgb(239, 126, 88);
}
.icon-eurodreams:before {
  content: "\e93e";
}

.icon-caballo:before {
  content: "\e915";
}
.icon-botes:before {
  content: "\e916";
}
.icon-logo-liga-color .path1:before {
  content: "\e917";
  color: rgb(255, 255, 255);
}
.icon-logo-liga-color .path2:before {
  content: "\e918";
  margin-left: -1em;
  color: rgb(149, 27, 129);
}
.icon-logo-liga-color .path3:before {
  content: "\e919";
  margin-left: -1em;
  color: rgb(0, 79, 159);
}
.icon-logo-liga-color .path4:before {
  content: "\e91a";
  margin-left: -1em;
  color: rgb(0, 144, 215);
}
.icon-logo-liga-color .path5:before {
  content: "\e91b";
  margin-left: -1em;
  color: rgb(0, 150, 64);
}
.icon-logo-liga-color .path6:before {
  content: "\e91c";
  margin-left: -1em;
  color: rgb(255, 237, 0);
}
.icon-logo-liga-color .path7:before {
  content: "\e91d";
  margin-left: -1em;
  color: rgb(241, 135, 0);
}
.icon-logo-liga-color .path8:before {
  content: "\e91e";
  margin-left: -1em;
  color: rgb(227, 6, 19);
}
.icon-logo-liga-color .path9:before {
  content: "\e91f";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-logo-liga-color .path10:before {
  content: "\e920";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-clasificacion:before {
  content: "\e921";
}
.icon-alarm:before {
  content: "\e900";
}
.icon-time:before {
  content: "\e900";
}
.icon-clock:before {
  content: "\e900";
}
.icon-search:before {
  content: "\e901";
}
.icon-zoom:before {
  content: "\e901";
}
.icon-magnifier:before {
  content: "\e901";
}
.icon-magnifying-glass:before {
  content: "\e901";
}
.icon-close:before {
  content: "\e902";
}
.icon-chevrons-right:before {
  content: "\e903";
}
.icon-arrow-right-circle:before {
  content: "\e904";
}
.icon-arrow-right:before {
  content: "\e905";
}
.icon-chevron-right:before {
  content: "\e906";
}
.icon-chevrons-left:before {
  content: "\e907";
}
.icon-arrow-left-circle:before {
  content: "\e908";
}
.icon-arrow-left:before {
  content: "\e909";
}
.icon-chevron-left:before {
  content: "\e90a";
}
.icon-bonoloto:before {
  content: "\e90b";
}
.icon-estrella:before {
  content: "\e90c";
}
// .icon-euromillon:before {
//   content: "\e90d";
// }
.icon-lototurf:before {
  content: "\e90e";
}
.icon-nacional:before {
  content: "\e90f";
}
.icon-primitiva:before {
  content: "\e910";
}
.icon-quiniela:before {
  content: "\e911";
}
.icon-quinigol:before {
  content: "\e912";
}
.icon-quintupleplus:before {
  content: "\e913";
}
.icon-calendar:before {
  content: "\e914";
}
.icon-date:before {
  content: "\e914";
}
.icon-schedule:before {
  content: "\e914";
}
.icon-euromillon:before {
  content: "\e92a";
}



$font: 'Montserrat', sans-serif;
$font-light: 'Montserrat-light', sans-serif;
$font-bold: 'Montserrat-bold', sans-serif;
$font-icons: 'icomoon';


// 4- ANIMATIONS -----------------------------------------
@-webkit-keyframes toTopFromBottom {
	0% {
		-webkit-transform: translateY(50%);
    opacity: 0;
	}
	100% {
    -webkit-transform: translateY(0%);
		opacity: 1;
	}
}
@-webkit-keyframes show {
    0% { opacity:0; }
    66% { opacity:0; }
    100% { opacity:1; }
}
@keyframes a-opacity {
  from {opacity: 0;}
  to {opacity: 1;}
}
@keyframes right-animation {
  0% {transform: translateY(110%);}
  30% { transform: translateY(110%); }
  100% {transform: translateY(0%);}
}
@keyframes leftAnimation {
  0% {transform: translateX(110%)}
  30% { transform: translateX(110%) }
  100% {transform: translateX(0%)}
}
@keyframes leftOpacity {
  0% {opacity: 0;}
  30% {opacity: 0; }
  100% {opacity: 1;}
}
@keyframes home-man-appear {
  0% {
    transform: translate(-50%, 40%);
    opacity: 0;
  }
  30% { 
    transform: translate(-50%, 40%); 
    opacity: 0;
  }
  100% {
    transform: translate(-50%, 0%);
    opacity: 1;
  }
}

$easeOutQuad: cubic-bezier(0.250, 0.460, 0.450, 0.940);
$easeInOutQuart: cubic-bezier(0.770, 0.000, 0.175, 1.000);
$easeInOutQuint: cubic-bezier(0.860, 0.000, 0.070, 1.000);
$easeInOutExpo: cubic-bezier(1.000, 0.000, 0.000, 1.000);
$easeInOutBack: cubic-bezier(0.680, -0.550, 0.265, 1.550);